import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c(VApp, [_c(VContainer, {
    attrs: {
      "grid-list-xs": ""
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "primary white--text",
    attrs: {
      "dark": "",
      "primary-title": ""
    }
  }, [_vm._v(" 安全登录验证 ")]), _c(VCardText, {
    staticClass: "subtitle-1 py-3"
  }, [_vm._v(" 你正在尝试微信验证完成网页登录操作 ")]), _c(VCardText, {
    staticClass: "d-flex justify-center py-10"
  }, [_c(VBtn, {
    attrs: {
      "elevation": "0",
      "width": "150",
      "height": "150",
      "fab": "",
      "color": "primary"
    }
  }, [_c(VIcon, {
    staticStyle: {
      "font-size": "60px"
    }
  }, [_vm._v("mdi-lock")])], 1)], 1), _vm.wxopenid ? _c(VCardActions, {
    staticClass: "d-flex justify-center py-5"
  }, [_vm.wxopenid.uid ? _c('div', [_c(VBtn, {
    staticClass: "px-3",
    attrs: {
      "rounded": "",
      "color": "success",
      "disabled": _vm.state
    },
    on: {
      "click": function ($event) {
        return _vm.loginForm(1);
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-1"
  }, [_vm._v("mdi-check")]), _vm._v(" 确认登陆 ")], 1)], 1) : _c('div', {
    staticClass: "text-h6"
  }, [_c('div', {
    staticClass: "py-2"
  }, [_vm._v("你的微信账号未与系统关联")]), _c(VBtn, {
    staticClass: "px-3",
    attrs: {
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": _vm.goLogin
    }
  }, [_vm._v(" 我要登录、注册 ")])], 1)]) : _vm._e()], 1)], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };